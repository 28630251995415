$(document).ready(function () {

    //validando btn do form
    $("#formContato").submit(function () {

        //disable the submit button
        $(".btnEnviarEmail").attr("disabled", true);

        return true;

    });


    //Criando o scroll entre seções
    $(".link-rolagem a").click(function (e) {
        e.preventDefault();
        var link = $(this).attr("href");

        $("html, body").stop().animate({scrollTop: $(link).offset().top - 50}, 750);

    });

    /*
    Carousel
*/
    $('.slide-cliente').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });

});
